.App {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-bar {
  flex: 0 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0)
    linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) repeat scroll 0
    0;
  color: rgb(52, 71, 103);
  border-radius: 0.3rem;
  min-height: 85vh;
  height: 100%;
}

.menu-home {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin: 0 auto;
  color: white;
}

.menu-divider {
  margin: 10px 0;
  height: 0.0625rem;
  border-width: 0 0 medium;
  border-style: solid solid none;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0)
  ) !important;
}
.menu-items {
  flex: 0 0 auto;
  display: flex;
  margin: 5px auto;
  width: 220px;
}

.menu-login {
  flex: 0 0 auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 220px;
}

.menu-selected {
  background: white;
  border-radius: 0.375rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.375rem -0.0625rem,
    rgba(0, 0, 0, 0.06) 0 0.125rem 0.25rem -0.0625rem;
}

.main {
  margin: 30px;
  flex: 1;
  position: relative;
}

.login-form {
  padding: 20px;
}

p {
  color: white;
}

.nav-icon {
  vertical-align: middle;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
